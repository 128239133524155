import React, {useEffect, useState} from 'react';
import {Tab, Form, Input} from 'semantic-ui-react';
import { Formik } from 'formik';
import "./styles.scss";
import {useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";

export interface DataItem {
    name: string;
    enableFieldName: string;
    paywallFieldName: string;
    enable: boolean;
    paywall: boolean;
}

const StreamingTabReadonly: React.FC = ({}) => {
    const group = useSelector((state: State) => state.groups.singleGroup);
    const [streamsData, setStreamsData] = useState<DataItem[] | null>(null);

    useEffect(() => {
        if(group) {
            setStreamsData([
                {
                    name: "HD Live",
                    enableFieldName: "isHdPlayerEnabled",
                    paywallFieldName: "isHdPlayerPaywallEnabled",
                    enable: group["isHdPlayerEnabled"],
                    paywall: group["isHdPlayerPaywallEnabled"]
                },
                {
                    name: "SD Live",
                    enableFieldName: "isSdPlayerEnabled",
                    paywallFieldName: "isSdPlayerPaywallEnabled",
                    enable: group["isSdPlayerEnabled"],
                    paywall: group["isSdPlayerPaywallEnabled"]
                },
                {
                    name: "HD Review",
                    enableFieldName: "isHdHlsEnabled",
                    paywallFieldName: "isHdHlsPaywallEnabled",
                    enable: group["isHdHlsEnabled"],
                    paywall: group["isHdHlsPaywallEnabled"]
                },
                {
                    name: "SD Review",
                    enableFieldName: "isSdHlsEnabled",
                    paywallFieldName: "isSdHlsPaywallEnabled",
                    enable: group["isSdHlsEnabled"],
                    paywall: group["isSdHlsPaywallEnabled"]
                },
                {
                    name: "HD VOD",
                    enableFieldName: "isHdVodEnabled",
                    paywallFieldName: "isHdVodPaywallEnabled",
                    enable: group["isHdVodEnabled"],
                    paywall: group["isHdVodPaywallEnabled"]
                },
                {
                    name: "SD VOD",
                    enableFieldName: "isSdVodEnabled",
                    paywallFieldName: "isSdVodPaywallEnabled",
                    enable: group["isSdVodEnabled"],
                    paywall: group["isSdVodPaywallEnabled"]
                },
            ])
        }
    }, [group]);

    return (
        group &&
            <Tab.Pane>
            <Formik key={'streamingTab'}
                initialValues={group.restriction}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values }) => {

                    return <Form size="large">
                        <div className="streams-header">
                            <span className="streams-header__enabled">Enabled</span>
                            <span className="streams-header__paywall">Paywall</span>
                        </div>
                        {
                            streamsData?.map(item => {
                                return (
                                    <div className="stream-type-section">
                                        <label>{item.name}</label>
                                        <Form.Checkbox
                                            type="checkbox"
                                            name={item.enableFieldName}
                                            disabled={true}
                                            checked={values[item.enableFieldName]} />
                                        <Form.Checkbox
                                            type="checkbox"
                                            name={item.paywallFieldName}
                                            disabled={true}
                                            checked={values[item.paywallFieldName]} />
                                    </div>
                                )
                            })
                        }
                        <div className="text-inputs-section">
                            <Form.Field inline>
                                <Input
                                    type="text"
                                    label={'Buffering (ms)'}
                                    name="buffering"
                                    disabled={true}
                                    placeholder="Enter value"
                                    value={values.bufferingMs}
                                    fluid
                                    inline={1}
                                    size="mini"
                                    key={'email'}
                                />
                            </Form.Field>
                            <Form.Field inline>
                                <Input
                                    type="text"
                                    label={'Latency Tolerance (ms)'}
                                    name="latencyTolerance"
                                    disabled={true}
                                    placeholder="Enter value"
                                    value={values.latencyToleranceMs}
                                    fluid
                                    inline={1}
                                    size="mini"
                                    key={'email'}
                                />
                            </Form.Field>
                        </div>
                    </Form>
                }}
            />
        </Tab.Pane>
    )
}

export default StreamingTabReadonly;
