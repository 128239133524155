import React, { useState } from 'react'
import './styles.scss';
import { history } from '../../utils/history';
import { User } from '../../common/types/user';
import { connect } from 'react-redux';
import { Role } from '../router/role';
import { State } from '../../common/types/state';
import MenuOption, { MenuOptionProps } from './children/menu-option';

export interface MenuBarProps {
    user: User;
}

const MenuBar: React.FC<MenuBarProps> = ({ user }) => {
    const [isStreamManagementOpened, setStreamManagementIsOpened] = useState(false);
    const [isNewStatisticsOpened, setNewStatisticsIsOpened] = useState(false);

    const menuOptions: MenuOptionProps[] = [
        {
            iconName: 'users',
            level: 1,
            onClick: () => { history.push('/group-management') },
            roles: [Role.Admin, Role.SuperAdmin, Role.MultiClientReadOnlyAdmin],
            text: 'Client Management',
            user: user,
            isOpened: true
        },
        {
            iconName: 'user',
            level: 1,
            onClick: () => { history.push('/users') },
            roles: [Role.ClientAdmin, Role.ClientAnalyst, Role.ClientSeniorAnalyst],
            text: 'User Management',
            user: user,
            isOpened: true
        },
        {
            iconName: 'tv',
            level: 1,
            onClick: () => { setNewStatisticsIsOpened(!isNewStatisticsOpened) },
            roles: [Role.Admin, Role.SuperAdmin, Role.ClientAdmin, Role.MultiClientReadOnlyAdmin],
            text: 'Statistics',
            user: user,
            isOpened: true
        },
        {
            iconName: 'tv',
            level: 2,
            onClick: () => { history.push('/statistics-table') },
            roles: [Role.Admin, Role.SuperAdmin, Role.ClientAdmin, Role.MultiClientReadOnlyAdmin],
            text: 'Summary',
            user: user,
            isOpened: isNewStatisticsOpened
        },
        {
            iconName: 'tv',
            level: 2,
            onClick: () => { history.push('/statistics-charts') },
            roles: [Role.Admin, Role.SuperAdmin, Role.ClientAdmin, Role.MultiClientReadOnlyAdmin],
            text: 'Detail',
            user: user,
            isOpened: isNewStatisticsOpened
        },
        {
            iconName: 'tv',
            level: 2,
            onClick: () => { history.push('/usage-data') },
            roles: [Role.Admin, Role.SuperAdmin, Role.ClientAdmin, Role.MultiClientReadOnlyAdmin],
            text: 'Download Usage Data',
            user: user,
            isOpened: isNewStatisticsOpened
        },
        {
            iconName: 'tv',
            level: 1,
            onClick: () => { setStreamManagementIsOpened(!isStreamManagementOpened) },
            roles: [Role.Admin, Role.SuperAdmin],
            text: 'Stream Management',
            user: user,
            isOpened: true
        },
        {
            iconName: 'tv',
            level: 2,
            onClick: () => { history.push('/stream-settings-management') },
            roles: [Role.Admin, Role.SuperAdmin],
            text: 'Stream Settings Management',
            user: user,
            isOpened: isStreamManagementOpened
        },
        {
            iconName: 'tv',
            level: 2,
            onClick: () => { history.push('/server-aliases-management') },
            roles: [Role.Admin, Role.SuperAdmin],
            text: 'Server Aliases Management',
            user: user,
            isOpened: isStreamManagementOpened
        },
        {
            iconName: 'tv',
            level: 1,
            onClick: () => { history.push('/sso-management') },
            roles: [Role.Admin, Role.SuperAdmin],
            text: 'SSO Management',
            user: user,
            isOpened: true
        },
        {
            iconName: 'tv',
            level: 1,
            onClick: () => { history.push('/announcements') },
            roles: [Role.Admin, Role.SuperAdmin],
            text: 'Announcements',
            user: user,
            isOpened: true
        },
    ]

    const setIcon = (user) => {
        if (user.pictureUrl) {
            return `url(${user.pictureUrl})`;
        } else {
            return `url(../../assets/stats_logo.png)`;
        }
    }

    return user && (
        <div className='sidebar-menu'>
            <div className='sidebar-menu-inner'>
                <header className="logo-header">
                    <div className="logo-wrapper">
                        <div className="logo" style={{
                            backgroundImage: setIcon(user)
                        }} />
                    </div>
                </header>
                <ul className="main-menu">
                    {
                        menuOptions.map((item, i) => {
                            return (
                                <MenuOption
                                    key={i}
                                    iconName={item.iconName}
                                    isOpened={item.isOpened}
                                    level={item.level}
                                    onClick={item.onClick}
                                    roles={item.roles}
                                    user={item.user}
                                    text={item.text}
                                />
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(MenuBar)
