import {all, call, put, takeLatest} from "redux-saga/effects";
import {fetchContentProviders} from "../routines";
import {getContentProvidersRequest} from "../../services/shared.service";

function* getContentProviders() {
    try {
        const response = yield call(getContentProvidersRequest);
        if (!response.data.sort()) {
            throw Error('Incorrect data');
        }
        yield put(fetchContentProviders.success(response.data));
    } catch (err) {
        yield put(fetchContentProviders.failure(err.message));
    }
}

export default function* sharedSaga() {
    yield all([
        takeLatest(fetchContentProviders, getContentProviders),
    ])
}