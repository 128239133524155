import React, {Fragment, useEffect, useState} from "react";
import {ClientsDropdown} from "../clients-dropdown/clients-dropdown";
import {Role} from "../../../router/role";
import {checkDateBeforeSet, SortByGroupName} from "../../../../utils/helpers";
import {Button, Icon, Loader, Pagination, PaginationProps, Table} from "semantic-ui-react";
import moment, {Moment} from "moment";
import {DateRangePicker, isInclusivelyAfterDay} from "react-dates";
import {downloadNewDetailedEventsCsv} from "../../../../services/statistics.service";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../common/types/state";
import {IContractSpec, IEventInfo} from "../models/contract-spec-model";
import {
    getContractSpecDataForGroupRoutine,
    getContractSpecDataRoutine,
} from "../../../../redux/routines";
import "./styles.scss";

export interface INewContractSpecPageProps {}

const NewContractSpecPage: React.FC<INewContractSpecPageProps> = () => {

    const [data, setData] = useState([]);
    const [focusedInput, setFocusedInput] = useState({} as any)
    const [detailedEvents, setDetailedEvents] = useState<IEventInfo[]>([]);
    const [displayedDetailedEvents, setDisplayedDetailedEvents] = useState<IEventInfo[]>([]);
    const [selectedClient, setSelectedClient] = useState<IContractSpec>();
    const [eventsCount, setEventsCount] = useState(0);
    const [begin, setBegin] = useState(0);
    const [end, setEnd] = useState(20);
    const [, setActivePage] = useState(1);
    const [startDate, setStartDate] = useState(moment().startOf("d").add(-9, 'd') as Moment);
    const [endDate, setEndDate] = useState(moment().startOf("d").add(-2, 'd') as Moment);

    const user = useSelector((state: State) => state.user);
    const logs = useSelector((state: State) => state.statistics.contractSpecLogs.source);
    const loading = useSelector((state: State) => state.statistics.contractSpecLogs.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        if ([Role.ClientAdmin].includes(user!.role)) {
            dispatch(getContractSpecDataForGroupRoutine({
                groupId: user!.groupId,
                startDate: startDate.clone().format("YYYY-MM-DDTHH:mm:ss"),
                endDate: endDate.clone().add(1, 'd').format("YYYY-MM-DDTHH:mm:ss")
            }));
        }
        if ([Role.Admin, Role.SuperAdmin, Role.MultiClientReadOnlyAdmin].includes(user!.role)) {
            dispatch(getContractSpecDataRoutine({
                startDate: startDate.clone().format("YYYY-MM-DDTHH:mm:ss"),
                endDate: endDate.clone().add(1, 'd').format("YYYY-MM-DDTHH:mm:ss")
            }));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        setData(logs);
    }, [logs])

    useEffect(() => {
        if (user.role === Role.ClientAdmin) {
            setSelectedClient(data.find((x: any) => x.clientName === user.group))
        }
        else {
            setSelectedClient(data.find((x: any) => x.clientName === 'MCR') || data[0]);
        }
    }, [data]);

    useEffect(() => {
        const allEvents: any = selectedClient?.eventsInfo;
        setDetailedEvents(allEvents);
    }, [selectedClient])

    useEffect(() => {
        setDisplayedDetailedEvents(detailedEvents?.slice(begin, end));
        selectedClient && setEventsCount(selectedClient!.eventsInfo.length);
    }, [detailedEvents])

    const moveNextPage = (event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
        const currentBegin = data.activePage as number * 20 - 20;
        const currentEnd = data.activePage as number * 20;

        setActivePage(data.activePage as number);
        setBegin(currentBegin);
        setEnd(currentEnd);
        setDisplayedDetailedEvents(detailedEvents?.slice(currentBegin, currentEnd));
    }

    const datesChangeHandler = (startDate: Moment | null, endDate: Moment | null) => {
        if (endDate === null) {
            endDate = startDate!.clone();
        }

        if (startDate === null) {
            return
        }

        checkDateBeforeSet(startDate, setStartDate);
        checkDateBeforeSet(endDate, setEndDate);
    }

    const downloadTableData = (data: any) => {
        if (navigator.msSaveBlob) {
            // Foe IE and Edge purposes
            return navigator.msSaveBlob(data, `${moment().format('DD/MMM/YY')}.csv`);
        }

        var blob = new Blob([data], { type: `application/csv` });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${selectedClient!.clientName}_${moment().format('DD/MMM/YY')}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    const renderDetailedEventsTable = () => {
        return <Fragment>
            <Table fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>User</Table.Cell>
                        <Table.Cell>Event Id</Table.Cell>
                        <Table.Cell>Event</Table.Cell>
                        <Table.Cell>Start time</Table.Cell>
                        <Table.Cell>Duration (hours)</Table.Cell>
                        <Table.Cell>Sport</Table.Cell>
                        <Table.Cell>Content provider</Table.Cell>
                        <Table.Cell>Stream Format</Table.Cell>
                        <Table.Cell>Location</Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        displayedDetailedEvents && displayedDetailedEvents!.map((log, i) => {
                            return <Table.Row key={i}>
                                <Table.Cell>{log.username}</Table.Cell>
                                <Table.Cell>{log.eventId}</Table.Cell>
                                <Table.Cell>{log.event}</Table.Cell>
                                <Table.Cell>{log.startTime}</Table.Cell>
                                <Table.Cell>{log.duration}</Table.Cell>
                                <Table.Cell>{log.sport}</Table.Cell>
                                <Table.Cell>{log.contentProvider}</Table.Cell>
                                <Table.Cell>{log.streamFormat}</Table.Cell>
                                <Table.Cell>{log.location}</Table.Cell>
                            </Table.Row>
                        })
                    }
                </Table.Body>
            </Table>
            <Pagination
                siblingRange={3}
                defaultActivePage={1}
                totalPages={!detailedEvents ? 1 : Math.ceil(detailedEvents && detailedEvents!.length / 20)}
                onPageChange={moveNextPage} />
        </Fragment>
    }

    return (
        <div className='contract-spec__container'>
            {
                 loading ?
                     <Loader active />
                     :
                         selectedClient && data.length !== 0 ?
                         <Fragment>
                             <div className='events-count__container'>
                                 <div className='tiers-table'>
                                     <ClientsDropdown
                                         possibleOptions={user.role === Role.ClientAdmin
                                             ? data.filter((x: any) => x.clientName === user.group)
                                                 .map((x: any) => ({ key: x.clientName, value: x.clientName, text: x.clientName }))
                                                 .sort(SortByGroupName)
                                             : data.map((x: any) => ({ key: x.clientName, value: x.clientName, text: x.clientName }))
                                                 .sort(SortByGroupName)}
                                         onChange={clientName => {
                                             const group = data.find((x: any) => x.clientName === clientName)
                                             setSelectedClient(group);
                                         }}
                                         isSingle
                                         values={selectedClient.clientName as any}
                                     />
                                 </div>
                                 <div className='events-counter'>
                                     {
                                         (user.role === Role.Admin || user.role === Role.SuperAdmin) &&
                                         <>
                                             <p>Total events consumed: </p>
                                             <p>
                                                 <b>{eventsCount}</b>
                                             </p>
                                         </>
                                     }
                                 </div>
                             </div>
                             <div className='detailed-events__container'>
                                 <div className='detailed-events-table'>
                                     <p>Events usage: </p>
                                     <div className='controls'>
                                         <DateRangePicker
                                             displayFormat={() => "DD/MM/YYYY"}
                                             startDate={startDate}
                                             endDate={endDate}
                                             onDatesChange={({ startDate, endDate }) => {
                                                 datesChangeHandler(startDate, endDate);
                                             }}
                                             startDateId={moment().toString()}
                                             endDateId={moment().add('7', 'd').toString()}
                                             focusedInput={focusedInput}
                                             onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                             isOutsideRange={day => isInclusivelyAfterDay(day + 1, moment())}
                                             minimumNights={0}
                                         />
                                         <Button onClick={() => downloadNewDetailedEventsCsv(selectedClient?.clientId, startDate.format("YYYY-MM-DDTHH:mm:ss"), endDate.format("YYYY-MM-DDTHH:mm:ss")).then((res) => downloadTableData(res!.data))}>Download as CSV</Button>
                                     </div>
                                     {
                                         renderDetailedEventsTable()
                                     }
                                 </div>
                             </div>
                         </Fragment>
                         :
                         <div className='controls no-data'>
                             <p>No data for selected period</p>
                             <DateRangePicker
                                 displayFormat={() => "DD/MM/YYYY"}
                                 startDate={startDate}
                                 endDate={endDate}
                                 onDatesChange={({ startDate, endDate }) => {
                                     datesChangeHandler(startDate, endDate);
                                 }}
                                 startDateId={moment().toString()}
                                 endDateId={moment().add('7', 'd').toString()}
                                 focusedInput={focusedInput}
                                 onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                 isOutsideRange={day => isInclusivelyAfterDay(day + 1, moment())}
                                 minimumNights={0}
                             />
                         </div>
            }
        </div>)
}

export default NewContractSpecPage;
