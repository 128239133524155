import { Formik, FormikProps } from "formik";
import { Role } from "../../../../../router/role";
import { Tab, Form, Grid, Button, Modal } from "semantic-ui-react";
import Select from "react-select";
import React from "react";
import * as Yup from 'yup';
import { User } from "../../../../../../common/types/user";
import './styles.scss'
import {useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";

export interface IUserInfoTabProps {
    user?: User;
    restrictions: any
    saveInfo: (userInfo: any) => any;
}

export interface IUserInfoProps {
    email: string;
    role: string;
}

const UserInfoTab: React.FC<IUserInfoTabProps> = ({ user, restrictions, saveInfo }) => {
    const currentUser = useSelector((state: State) => state.user);

    const domains = restrictions.domainsWithAccess
        .split(",")
        .map(val => val.trim());

    const isDomainAllowed = function(email: string) {
        if (!email) {
            return false
        }
        const testedDomain = email.substring(email.indexOf("@")+1, email.length);
        return domains.includes(testedDomain);
    }

    const roleOptions = (currentUserRole) => {
        if (currentUserRole === Role.SuperAdmin || currentUserRole === Role.Admin) {
            return [
                Role.ClientAdmin,
                Role.ClientAnalyst,
                Role.ClientSeniorAnalyst,
                Role.User,
                Role.APIAccessOnly,
                Role.MultiClientReadOnlyAdmin
            ].map(item => {
                return {
                    value: item,
                    label: item
                }
            })
        } else {
            return [
                Role.ClientAnalyst,
                Role.ClientSeniorAnalyst,
                Role.User,
                Role.APIAccessOnly
            ].map(item => {
                return {
                    value: item,
                    label: item
                }
            })
        }
    }

    let validationSchema = restrictions.isDomainOnlyEnabled ?
        Yup.object()
            .shape({
            email: Yup.string()
                .required('Email is required')
                .test("match", "This domain is not specified in restriction", function() {
                    return isDomainAllowed(this.parent.email);
                })
        }) :
        Yup.object()
            .shape({
            email: Yup.string().required('Email is required')
        });

    return (
        <Tab.Pane>
            <Formik
                initialValues={
                    {
                        email: user!.email,
                        role: user!.role || Role.User
                    }
                }
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    let model;
                    model = user!.email ? {
                        role: values.role || Role.User,
                        }
                        : {
                        username: values.email,
                        email: values.email,
                        role: values.role || Role.User,
                    };
                    setSubmitting(false);
                    saveInfo(model);
                }}
                render={
                    ({ values, handleSubmit, handleChange, errors, touched }: FormikProps<IUserInfoProps>) => (

                        <Form size="large" onSubmit={handleSubmit}>
                            <Grid>
                                <Grid.Row centered>
                                    <Grid.Column width={8}>
                                        <Form.Input
                                            label="Email"
                                            fluid
                                            disabled={user!.email !== ''}
                                            onChange={handleChange}
                                            type="email"
                                            name="email"
                                            placeholder="Email address"
                                            value={values.email}
                                        />
                                        {touched.email && errors.email &&
                                            <div className='error-message'>{errors.email}</div>}
                                        <div className="field">
                                            <label>Role</label>
                                            <Select
                                                name="role"
                                                placeholder="Role"
                                                defaultValue={{
                                                    value: values.role,
                                                    label: values.role
                                                }}
                                                options={roleOptions(currentUser?.role)}
                                                onChange={(e) => {
                                                    values.role = (e as any).value;
                                                }}
                                            />
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row centered>
                                    <Button type='submit'>Save</Button>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    )}
            />
        </Tab.Pane>
    )
}

export default UserInfoTab;
