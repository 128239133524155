export const Role = {
    SuperAdmin: 'Super Admin',
    Admin: 'Admin',
    ClientAdmin: 'Client Admin',
    ClientSeniorAnalyst: 'Client Senior Analyst',
    ClientAnalyst: 'Client Analyst',
    User: 'User',
    APIAccessOnly: 'API Access Only',
    MultiClientReadOnlyAdmin: 'MultiClient ReadOnly Admin'
}
