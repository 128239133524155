import { combineReducers } from "redux";
import { userReducer } from "./reducers/user";
import {contentProvidersReducer, countriesReducer, rolesReducer, sportsReducer} from "./reducers/shared";
import { loadingReducer } from "./reducers/loading";
import { competitionsReducer } from "./reducers/schedules";
import { groupsReducer } from "./reducers/group";
import { reducer as toastrReducer } from 'react-redux-toastr'
import { serverAliasesReducer } from "./reducers/server-aliases";
import { streamSettingsReducer } from "./reducers/stream-settings";
import { eventsCountReducer } from "./reducers/eventCount";
import { detailedEventsReducer } from "./reducers/detailedEvents";
import { userDeviceReducer } from "./reducers/device";
import {statisticsReducer} from "./reducers/statistics";
import {ssoReducer} from "./reducers/sso";
import {announcementsReducer} from "./reducers/announcements";

export default combineReducers({
    user: userReducer,
    isLoading: loadingReducer,
    countries: countriesReducer,
    roles: rolesReducer,
    sports: sportsReducer,
    competitions: competitionsReducer,
    groups: groupsReducer,
    statistics: statisticsReducer,
    toastr: toastrReducer,
    serverAliases: serverAliasesReducer,
    streamSettings: streamSettingsReducer,
    eventsCount: eventsCountReducer,
    detailedEvents: detailedEventsReducer,
    userDevices: userDeviceReducer,
    ssoDomains: ssoReducer,
    announcements: announcementsReducer,
    contentProviders: contentProvidersReducer
});
