import React from 'react';
import {Tab, Form, Input, Dropdown} from 'semantic-ui-react';
import { Formik } from 'formik';
import "./styles.scss";
import {useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";

const MainInfoTabReadonly: React.FC = () => {
    const group = useSelector((state: State) => state.groups.singleGroup);
    const {countries, groups} = useSelector((state: State) => state);

    return (
        group &&
        <Tab.Pane>
            <Formik
                key={'mainInfoForm'}
                initialValues={group}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values }) => {

                    return <Form size="large">
                        <Form.Group widths='equal'>
                            <Form.Field inline>
                                <Input
                                    label={'Client Name*'}
                                    className="input-readonly"
                                    inline={1}
                                    size="mini"
                                    fluid
                                    key={'name'}
                                    type="text"
                                    name="name"
                                    disabled={true}
                                    placeholder="Client Name"
                                    value={values.name} />
                            </Form.Field>
                            <Form.Field inline>
                                <Input
                                    label={'Contact*'}
                                    inline={1}
                                    size="mini"
                                    fluid
                                    key={'contact'}
                                    type="text"
                                    name="contact"
                                    disabled={true}
                                    placeholder="Contact"
                                    value={values.contact} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field inline>
                            <Input
                                type="email"
                                label={'Email*'}
                                name="email"
                                disabled={true}
                                placeholder="Email address"
                                value={values.email}
                                fluid
                                inline={1}
                                size="mini"
                                key={'email'}
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <Input
                                type="email"
                                label={'Admin Email*'}
                                name="clientAdminEmail"
                                disabled={true}
                                placeholder="Admin Email address"
                                value={values.clientAdminEmail}
                                fluid
                                inline={1}
                                size="mini"
                                key={'clientAdminEmail'}
                            />
                        </Form.Field>
                        <label className="region-label">Region*</label>
                        <Form.Field inline>
                            <Dropdown
                                placeholder={"Select Country"}
                                fluid
                                search
                                multiple={true}
                                disabled={true}
                                value={values.addresses}
                                options={countries && countries.map(s => {
                                    return {
                                        key: s.alpha2Code,
                                        value: s.englishName,
                                        text: s.englishName,
                                    }
                                })}
                            />
                        </Form.Field>
                        <div className="middle-section">
                            <div className="middle-section__left">
                                <Form.Group>
                                    <Form.Field inline>
                                        <Input
                                            label={'Number of Licenses*'}
                                            inline={1}
                                            size="mini"
                                            key={'numberOfLicences'}
                                            type="number"
                                            name="numberOfLicences"
                                            disabled={true}
                                            placeholder="Licenses"
                                            value={values.licensesCount} />
                                    </Form.Field>
                                </Form.Group>
                            </div>
                        </div>
                        <div style={{
                            display: 'block',
                            margin: '0 0 .28571429rem 0',
                            color: 'rgba(0,0,0,.87)',
                            fontSize: '0.92857143em',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}>
                            Upload client logo
                        </div>
                        {
                            group.picture &&
                            <div className='logo-container'>
                                <img src={group.picture} alt='Logo' />
                            </div>
                        }
                    </Form>
                }} />
        </Tab.Pane>
    )
}

export default MainInfoTabReadonly;
