import React, { Fragment } from 'react';
import { Table, Icon, Button, Loader, Checkbox, Popup, Pagination, PaginationProps, Search } from 'semantic-ui-react';
import { User } from '../../../../common/types/user';
import { connect } from 'react-redux';
import {
    removeOrRestoreUserByEmailRoutine,
    removeUserRoutine,
    fetchRoles,
    toggleIndividualRestrictions,
    fetchGroup,
    resetPassword,
    deleteGroup, toggleExternalUserRoutine,
} from '../../../../redux/routines';
import UsersModal from '../users-modal/users-modal';
import './styles.scss'
import RestrictionModal from '../restrictions-modal/restrictions-modal';
import { getDateDiffFromNowInDays } from '../../../../utils/helpers';
import { DeleteModal } from '../delete-confirmation-modal/delete-confirmation-modal';
import moment from 'moment';
import { State } from '../../../../common/types/state';
import { Group } from '../../../../common/types/group';
import { Role } from '../../../router/role';
import { ConfirmModal } from '../../../../common/components/confirm-model';
import { IUserDelete } from '../../../../common/types/userDelete';
import removeDevicesIcon from '../../../../assets/remove_devices.svg';
import {RemoveGroupDevicesModal} from "../remove-devices-modal/remove-devices-modal";

export interface IUsersTableProps {
    currentUser: User;
    removeOrRestoreUser: (model: IUserDelete) => void;
    removeUser: (id: any) => void;
    users: User[];
    fetchRoles: (user) => void;
    toggleIndividualRestrictions: (restriction) => void;
    isLoading: boolean;
    fetchGroup: (id) => void;
    groups: any;
    isGroupViewPage: boolean;
    id?: number;
    resetPassword: (data: { email: string }) => void;
    toggleExternalUser: (userId: number) => void;
}

export interface IUsersTableState {
    showSpinner: boolean;
    deleteModalUser?: User;
    updateModalUser?: User;
    isNewModal: boolean;
    begin: number;
    end: number;
    activePage: number;
    users: User[];
    searchCriteria: string;
    isLoading: boolean;
    deleteModalOpen: boolean;
    updateModalOpen: boolean;
    restrictionsModalOpen: boolean;
    isExternalUserModalOpen: boolean;
    userEmailForExternalUser: string | undefined;
    isNotificationModalOpen: boolean;
    userEmailForNotification: string | undefined;
    isResetPasswordModalOpen: boolean;
    userEmailForResetPassword: string | undefined;
    actionType: string;
    sortingCriteria: string;
    clientAdmin? : User;
    removeDevicesModalOpen: boolean;
}

class UsersTable extends React.Component<IUsersTableProps, IUsersTableState> {
    constructor(props) {
        super(props);
        this.moveNextPage = this.moveNextPage.bind(this);
        this.state = {
            showSpinner: true,
            begin: 0,
            end: 20,
            activePage: 1,
            users: [],
            isNewModal: false,
            searchCriteria: '',
            isLoading: false,
            restrictionsModalOpen: false,
            updateModalOpen: false,
            deleteModalOpen: false,
            isExternalUserModalOpen: false,
            userEmailForExternalUser: undefined,
            isNotificationModalOpen: false,
            userEmailForNotification: undefined,
            isResetPasswordModalOpen: false,
            userEmailForResetPassword: undefined,
            actionType: 'Delete',
            sortingCriteria: '',
            removeDevicesModalOpen: false
        };
    }

    createUserModel: User = {
        username: '',
        email: '',
        role: Role.User,
        id: 0,
        group: '',
        groupId: 0,
        refreshToken: '',
        lastLogin: '',
        devices: [],
        isNotificationsEnabled: true,
        isExternalUser: false,
        isDeleted: false
    };

    componentDidMount() {
        const { fetchRoles, fetchGroup, currentUser } = this.props;

        if ([Role.ClientAdmin, Role.ClientAnalyst, Role.ClientSeniorAnalyst].includes(currentUser.role)) {
            fetchGroup(currentUser.groupId)
        } else {
            const id = Number(this.props.id);
            fetchGroup(id);
        }
        this.onSorting("default");
        fetchRoles(currentUser);
    }

    componentWillReceiveProps = (props: any) => {
        this.setState({
            users: props.users ? props.users.sort(this.compareFunction).slice(this.state.begin, this.state.end) : [],
            clientAdmin : props?.groups?.source?.clientAdmin
        })
    }

    componentDidUpdate(prevProps: Readonly<IUsersTableProps>, prevState: Readonly<IUsersTableState>, snapshot?: any) {
        if (prevState.sortingCriteria !== this.state.sortingCriteria) {
            const sortedUsers = this.props.users ? this.props.users.sort(this.compareFunction) : [];
            this.setState({
                users: sortedUsers
                    .filter(u => u.email.toLowerCase()
                    .includes(this.state.searchCriteria.toLowerCase()))
                    .slice(this.state.begin, this.state.end)
            })
        }
    }

    closeRestrictionsModal = () => this.setState({ restrictionsModalOpen: false })
    closeUpdateModal = () => this.setState({ updateModalOpen: false, updateModalUser: undefined })
    closeDeleteModal = () => this.setState({ deleteModalOpen: false })
    closeRemoveDevicesModal = () => this.setState({ removeDevicesModalOpen: false })

    openRestrictionsModal = () => this.setState({ restrictionsModalOpen: true })
    openDeleteModal = (el) => this.setState({ deleteModalOpen: true, deleteModalUser: el, actionType: 'Delete' })
    openRestoreModal = (el) => this.setState({ deleteModalOpen: true, deleteModalUser: el, actionType: 'Restore' })
    openUpdateModal = (item, isNew) => this.setState({ updateModalOpen: true, updateModalUser: item, isNewModal: isNew })
    openRemoveDevicesModal = () => this.setState({ removeDevicesModalOpen: true })

    forgotPassword = (email: string) => this.props.resetPassword({ email: email });

    handleSearchChange = (e, value) => {
        this.setState({
            isLoading: true,
            searchCriteria: value
        })

        if (this.state.searchCriteria.length > 0 && this.props) {
            this.setState({
                users: this.props.users
            })
        }
    }

    handleExternalUserRequest = () => {
        this.handleConfirmRequest("userEmailForExternalUser", "isExternalUserModalOpen", (user) => {
            user.isExternalUser = !user.isExternalUser;
            this.props.toggleExternalUser(user.id);
        })
    }

    handleResetPasswordRequest = () => {
        this.handleConfirmRequest("userEmailForResetPassword", "isResetPasswordModalOpen", (user) => {
            this.forgotPassword(user.email)
        })
    }

    handleConfirmRequest = (nameOfEmailProperty: string, nameOfTriggerProperty: string, action: (user: User) => void) => {
        if (!this.state[nameOfEmailProperty]) {
            return;
        }
        const user = this.state.users.find(x => x.email === this.state[nameOfEmailProperty]);
        if (!user) {
            return;
        }
        action(user);

        this.setState({ [nameOfTriggerProperty]: false } as any)
    }

    onSorting = (criteria: string) => {
        if (criteria !== this.state.sortingCriteria) {
            this.setState({ sortingCriteria: criteria });
        } else {
            this.setState({ sortingCriteria: 'reverse-' + criteria });
        }
    }

    compareFunction = (a: User, b: User) => {
        switch (this.state.sortingCriteria) {
            case "default": return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1;

            case "date": return Date.parse(a.lastLogin) - Date.parse(b.lastLogin);

            case "reverse-date": return Date.parse(b.lastLogin) - Date.parse(a.lastLogin);

            case "role": return a.role.charCodeAt(0) - b.role.charCodeAt(0);

            case "reverse-role": return b.role.charCodeAt(0) - a.role.charCodeAt(0);

            case "activity": return getDateDiffFromNowInDays(new Date(Date.now()), new Date(a.lastLogin!)) ? -1 : 1;

            case "reverse-activity": return getDateDiffFromNowInDays(new Date(Date.now()), new Date(a.lastLogin!)) ? 1 : -1;

            case "isExternal": return a.isExternalUser ? -1 : 1;

            case "reverse-isExternal": return a.isExternalUser ? 1 : -1;

            default: return a.id - b.id;
        }
    }

    async moveNextPage(event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) {
        await this.setState({
            activePage: data.activePage as number,
            begin: data.activePage as number * 20 - 20,
            end: data.activePage as number * 20
        })
        const sortedUsers = this.props.users ? this.props.users.sort(this.compareFunction) : [];
        this.setState({
            users: sortedUsers.slice(this.state.begin, this.state.end)
        })
    }

    getToggleExternalUserConfirmationQuestion = (userEmail) => {
        const user = this.state.users.find(x => x.email === userEmail);
        if (user) {
            if (user!.isExternalUser) {
                return `Are you sure you want to enable password login for ${this.state.userEmailForExternalUser}?`;
            } else {
                return `Are you sure you want to disable password login for ${this.state.userEmailForExternalUser}?`;
            }
        } else {
            return "";
        }
    }



    render() {
        const { isLoading, users, groups, isGroupViewPage, currentUser } = this.props;
        const { updateModalUser } = this.state;

        return (!this.state || isLoading) || !this.state.users || !this.props.users
            ? (<Loader active style={{ position: 'absolute', left: '55%', top: '50%' }} />)
            : (
                <div className="users-table-container">
                    <h2>Group Info Page: {groups.source.name}</h2>
                    <div className="users-table-buttons">
                        <Button onClick={this.openRestrictionsModal.bind(this)}>View restrictions</Button>
                        <RestrictionModal
                            closeModal={this.closeRestrictionsModal.bind(this)}
                            modalOpened={this.state.restrictionsModalOpen}
                        />
                        {
                            [Role.SuperAdmin, Role.Admin, Role.ClientAdmin].includes(currentUser.role) &&
                                <>
                                    <Button onClick={this.openRemoveDevicesModal.bind(this)}>
                                        <img src={removeDevicesIcon} alt="remove devices" className="remove-devices-icon"/>
                                    </Button>
                                    <RemoveGroupDevicesModal
                                        closeModal={this.closeRemoveDevicesModal.bind(this)}
                                        modalOpened={this.state.removeDevicesModalOpen}
                                        groupId={groups.source.id}
                                    />
                                </>
                        }

                    </div>
                    <div className="user-table_remaining-label">
                        {
                            `Remaining licenses: ${groups.source.licensesCount - this.props.users.filter(x => !x.isDeleted).length}`
                        }
                    </div>
                    <div className="user-table_error">
                        {groups.exception ? `Error: ${groups.exception}` : ''}
                    </div>
                    <Search
                        loading={isLoading}
                        onSearchChange={(e, data) => this.handleSearchChange(e, data.value)}
                        results={[]}
                        value={this.state.searchCriteria}
                    />
                    <Table celled className="competitions-table">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell className="sorting-button" onClick={() => this.onSorting("date")}>Last login Date</Table.HeaderCell>
                                <Table.HeaderCell>Login Location</Table.HeaderCell>
                                <Table.HeaderCell className="sorting-button" onClick={() => this.onSorting("role")}>Role</Table.HeaderCell>
                                {
                                    [Role.SuperAdmin, Role.Admin, Role.ClientAdmin].includes(currentUser.role) &&
                                    <Fragment>
                                        <Table.HeaderCell className="sorting-button" onClick={() => this.onSorting("activity")}>Active</Table.HeaderCell>
                                        <Table.HeaderCell className="sorting-button" onClick={() => this.onSorting("isExternal")}>Password login disabled</Table.HeaderCell>
                                    </Fragment>
                                }
                                {
                                    !isGroupViewPage
                                    && [Role.SuperAdmin, Role.Admin, Role.ClientAdmin, Role.ClientSeniorAnalyst]
                                        .includes(currentUser.role)
                                        ||
                                    currentUser.role === Role.MultiClientReadOnlyAdmin && currentUser.group === groups.singleGroup.name
                                    &&
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.users
                                    .filter(u => u.email.toLowerCase()
                                    .includes(this.state.searchCriteria.toLowerCase()))
                                    .map(el => {
                                        return <Table.Row key={el.id}>
                                            <Table.Cell>
                                                <div className="username-email-wrapper">
                                                    <p>{el.email}</p>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {el.lastLogin!.toString() !== "0001-01-01T00:00:00" && moment(el.lastLogin).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {el.location}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {el.role}
                                            </Table.Cell>
                                            {
                                                [Role.SuperAdmin, Role.Admin, Role.ClientAdmin].includes(currentUser.role) &&
                                                this.renderIcon(getDateDiffFromNowInDays(new Date(Date.now()), new Date(el.lastLogin!)))
                                            }
                                            {
                                                [Role.SuperAdmin, Role.Admin, Role.ClientAdmin].includes(currentUser.role)
                                                && <Fragment>
                                                    <Table.Cell>
                                                        <Checkbox
                                                            type="checkbox"
                                                            disabled={isGroupViewPage}
                                                            checked={el.isExternalUser}
                                                            onChange={(_, props) => {
                                                                this.setState({ userEmailForExternalUser: el.email, isExternalUserModalOpen: true });
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                </Fragment>
                                            }
                                            {
                                                !isGroupViewPage
                                                &&
                                                ([Role.SuperAdmin, Role.Admin, Role.ClientAdmin, Role.ClientSeniorAnalyst]
                                                    .includes(currentUser.role)
                                                    ||
                                                    currentUser.role === Role.MultiClientReadOnlyAdmin && currentUser.group === el.group
                                                )
                                                &&
                                                <Table.Cell className='action-buttons' style={{ display: 'flex' }}>
                                                    {
                                                        [Role.SuperAdmin, Role.ClientAdmin, Role.Admin, Role.MultiClientReadOnlyAdmin]
                                                            .includes(currentUser.role) &&
                                                        (
                                                            <Fragment>
                                                                <Popup
                                                                    content='Edit'
                                                                    trigger={
                                                                        <Button disabled={el.isDeleted} onClick={() => this.openUpdateModal(el, false)} primary>
                                                                            <Icon name='edit' />
                                                                        </Button>} />
                                                                {
                                                                    el.isDeleted
                                                                        ? <Popup
                                                                            content='Restore'
                                                                            trigger={
                                                                                <Button
                                                                                    disabled={
                                                                                        users.filter(x => !x.isDeleted).length >= groups.source.licensesCount
                                                                                        }
                                                                                    onClick={() => this.openRestoreModal(el)}
                                                                                >
                                                                                    <Icon name='spinner' />
                                                                                </Button>} />
                                                                        : <Popup
                                                                            content='Delete'
                                                                            trigger={
                                                                                <Button
                                                                                    disabled={currentUser.id === el.id}
                                                                                    onClick={() => this.openDeleteModal(el)}
                                                                                    secondary>
                                                                                    <Icon name='delete' />
                                                                                </Button>} />
                                                                }

                                                            </Fragment>
                                                        )
                                                    }
                                                    {
                                                        [Role.SuperAdmin, Role.Admin, Role.ClientAdmin, Role.ClientSeniorAnalyst]
                                                            .includes(currentUser.role) &&
                                                        (
                                                            <Fragment>
                                                                <Popup
                                                                    content='Reset password'
                                                                    trigger={
                                                                        <Button
                                                                            disabled={el.isDeleted || el.isExternalUser}
                                                                            onClick={() => {
                                                                                this.setState({ userEmailForResetPassword: el.email, isResetPasswordModalOpen: true });
                                                                            }}>
                                                                            <Icon name="key" />
                                                                        </Button>} />
                                                            </Fragment>
                                                        )
                                                    }
                                                </Table.Cell>
                                            }
                                        </Table.Row>
                                    })
                            }
                        </Table.Body>
                    </Table>
                    <Pagination
                        siblingRange={3}
                        defaultActivePage={1}
                        totalPages={this.state.searchCriteria ? 1 : Math.ceil(users.length / 20)}
                        onPageChange={this.moveNextPage}
                    />
                    {
                        !isGroupViewPage && (users.filter(x => !x.isDeleted).length < groups.source.licensesCount) &&
                        [Role.SuperAdmin, Role.Admin, Role.ClientAdmin].includes(currentUser.role) &&
                        <Button onClick={() => this.openUpdateModal(this.createUserModel, true)}>Create user</Button>
                    }
                    <DeleteModal
                        modalOpened={this.state.deleteModalOpen}
                        object={this.state.deleteModalUser}
                        actionType={this.state.actionType}
                        removeGroup={deleteGroup}
                        removeOrRestoreUserAction={this.removeOrRestoreUser.bind(this)}
                        removeUserAction={this.removeUser.bind(this)}
                        type='User'
                        closeModal={this.closeDeleteModal.bind(this)}
                    />
                    {updateModalUser &&
                        <UsersModal
                            isNew={this.state.isNewModal}
                            user={this.state.updateModalUser as User}
                            closeModal={this.closeUpdateModal.bind(this)}
                            modalOpened={this.state.updateModalOpen}>
                        </UsersModal>}
                    <ConfirmModal
                        modalOpened={this.state.isExternalUserModalOpen}
                        closeModal={() => this.setState({ isExternalUserModalOpen: false })}
                        onConfirmAction={this.handleExternalUserRequest}
                        label={this.getToggleExternalUserConfirmationQuestion(this.state.userEmailForExternalUser)}
                    />
                    <ConfirmModal
                        modalOpened={this.state.isResetPasswordModalOpen}
                        closeModal={() => this.setState({ isResetPasswordModalOpen: false })}
                        onConfirmAction={this.handleResetPasswordRequest}
                        label={`Are you sure you want to reset password for ${this.state.userEmailForResetPassword}?`}
                    />
                </div>
            )
    }

    removeOrRestoreUser(model: IUserDelete) {
        const { removeOrRestoreUser } = this.props;

        this.setState({ deleteModalOpen: false });
        removeOrRestoreUser(model);
    }

    removeUser(id: any) {
        const { removeUser } = this.props;

        this.setState({ deleteModalOpen: false });
        removeUser(id);
    }

    renderIcon(property: boolean) {
        return property
            ? <Table.Cell width={1}>
                <Icon color='green' name='checkmark' size='large' />
            </Table.Cell>
            : <Table.Cell width={1} />
    }
}

const mapStateToProps = (state: State) => {
    return {
        currentUser: state.user,
        users: (state.groups.singleGroup as Group)?.users,
        error: (state.groups.exception),
        isLoading: state.groups.isLoading,
        groups: state.groups
    }
}

const mapDispatchToProps = {
    removeOrRestoreUser: removeOrRestoreUserByEmailRoutine,
    removeUser: removeUserRoutine,
    fetchRoles,
    fetchGroup,
    toggleIndividualRestrictions,
    toggleExternalUser: toggleExternalUserRoutine,
    resetPassword: resetPassword,
    deleteGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)

