import React, {Fragment, useEffect, useState} from "react";
import {ClientsDropdown} from "../clients-dropdown/clients-dropdown";
import {DateRangePicker, isInclusivelyAfterDay} from "react-dates";
import moment, {Moment} from "moment";
import {Button, Icon, Loader, Table} from "semantic-ui-react";
import {downloadGroupSummaryCsv, downloadSummaryCsv} from "../../../../services/statistics.service";
import {Role} from "../../../router/role";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../common/types/state";
import {
    getStatisticsTableDataForGroupRoutine,
    getStatisticsTableDataRoutine
} from "../../../../redux/routines";
import "./styles.scss";
import {checkDateBeforeSet} from "../../../../utils/helpers";
import {AxiosResponse} from "axios";

export interface INewStatisticsTablePageProps {}

export interface IUIItem {
    type: string,
    label: string
}

enum Colors {
    Odd = '#ABBBC4',
    Even = '#DBE2E6',
    Background = '#D6CFCB',
    Text = '#303641',
    Border = '#b0b0b0',
    Light = '#F1F1F1',
    Dark = '#4C5B5C'
}

const headingStyles = { backgroundColor: '#4C5B5C', color: 'white' };

const NewStatisticsTablePage: React.FC<INewStatisticsTablePageProps> = () => {

    const [data, setData] = useState([] as any);
    const [columns, setColumns] = useState([] as string[]);
    const [startDate, setStartDate] = useState(moment().startOf("d").add(-9, 'd') as Moment);
    const [endDate, setEndDate] = useState(moment().startOf("d").add(-2, 'd') as Moment);
    const [focusedInput, changeFocusedInput] = useState(null as any);
    const [allPossibleColumns, setAllPossibleColumns] = useState([] as any[]);
    const [typesExpanded, setTypesExpanded] = useState(false);
    const [sportsExpanded, setSportsExpanded] = useState(false);
    const [types, setTypes] = useState([] as IUIItem[])
    const [sports, setSports] = useState([] as IUIItem[]);
    const [fileData, setFileData] = useState(data);

    const dispatch = useDispatch();

    const user = useSelector((state: State) => state.user);
    const logs = useSelector((state: State) => state.statistics.summaryLogs.source);
    const loading = useSelector((state: State) => state.statistics.summaryLogs.isLoading);

    useEffect(() => {
        refreshPageData(startDate, endDate)
    }, [startDate, endDate])

    useEffect(() => {
        setData(logs);
    }, [logs]);

    useEffect(() => {
        setDataForFileLoading();
    }, [columns]);

    useEffect(() => {
        const videoTypes = [
            { type: 'hdVod', label: 'HD VOD' },
            { type: 'sdVod', label: 'SD VOD' },
            { type: 'hd', label: 'HD Live' },
            { type: 'sd', label: 'SD Live' },
            { type: 'hdReview', label: 'HD Review' },
            { type: 'sdReview', label: 'SD Review' }
        ];
        const columnNames = data!.map(x => x.clientName);
        const defaultClient = columnNames.find(x => x === user.group) || "";
        const possibleColumns = columnNames.map(x => ({ key: x, text: x, value: x }));
        const allPossibleSports = data.reduce((acc, val) => {
            const sports = val.sports!.map(x => x.sport);
            return [...acc, ...sports];
        },[])
        const uniqueSports: any = Array.from(new Set(allPossibleSports))!.map((x: any) => ({ type: x, label: x }));
        setColumns([defaultClient]);
        setAllPossibleColumns(possibleColumns);
        setTypes(videoTypes);
        setSports(uniqueSports);
    }, [data])

    const datesChangeHandler = (startDate: Moment | null, endDate: Moment | null) => {
        if (endDate === null) {
            endDate = startDate!.clone();
        }

        if (startDate === null) {
            return
        }

        setStartDate(moment(startDate).startOf("d"));
        setEndDate(moment(endDate).startOf("d"));

        // checkDateBeforeSet(startDate, setStartDate);
        // checkDateBeforeSet(endDate, setEndDate);
    }

    const setColumnsData = (data: string[]) => {
          setColumns(data);
    }

    const downloadTableData = (data: any) => {
        if (navigator.msSaveBlob) {
            // Foe IE and Edge purposes
            return navigator.msSaveBlob(data, "file.csv");
        }

        var blob = new Blob([data], { type: "application/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Summary_${moment().format('DD/MMM/YY')}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    const renderColumnsRow = () => {
        return (
            <Table.Row>
                <Table.HeaderCell
                    style={{
                        backgroundColor: Colors.Dark,
                        color: 'white',
                        borderRight: `1px solid ${Colors.Border}`
                    }}/>
                {
                    columns.map((x, i) => {
                        return (
                            <Table.HeaderCell
                                key={i}
                                style={{
                                    backgroundColor: Colors.Dark,
                                    color: 'white',
                                    borderRight: `1px solid ${Colors.Border}`
                                }}
                            >
                                {x}
                            </Table.HeaderCell>
                        );
                    })
                }
            </Table.Row>
        )
    }

    const renderRow = (name: string, field: string, index: number) => {
        return (
            <Table.Row>
                <Table.Cell style={{ ...headingStyles, borderRight: 'none', width: '220px' }}>
                    {name}
                </Table.Cell>
                {
                    columns.map((client) => {
                        return <Table.Cell
                            key={client}
                            style={{
                            backgroundColor: index % 2 === 0 ? Colors.Even : Colors.Odd,
                            color: Colors.Text,
                            borderRight: `1px solid ${Colors.Border}`
                        }}>
                            {
                                data.find(x => x.clientName === client) && data.find(x => x.clientName === client)[field]
                            }
                        </Table.Cell>
                    })
                }
            </Table.Row>
        );
    }

    const renderEmptyRowWithDropDown = (name: string, setStateExpanded: (value: React.SetStateAction<boolean>) => void, value: boolean, index: number) => {
        return (
            <Table.Row onClick={() => setStateExpanded(value)}>
                <Table.Cell style={headingStyles}>
                    {name}
                    <Icon name="arrow down"></Icon>
                </Table.Cell>
                {
                    columns.map((_, i) => (
                        <Table.Cell
                            key={i}
                            style={{
                            color: Colors.Text,
                            backgroundColor: index % 2 === 0 ? Colors.Even : Colors.Odd,
                            borderRight: `1px solid ${Colors.Border}`
                        }} />)
                    )
                }
            </Table.Row>
        )
    }

    const renderSubitemsRow = (statement: boolean, collection: IUIItem[], field: string) => {
        return statement && collection.map((item, i) => {
            return <Table.Row key={i}>
                <Table.Cell style={{ ...headingStyles, color: '#e3dede', bold: false, fontWeight: 400 }}>
                    {item.label}
                </Table.Cell>
                {
                    columns.map((client) => {
                        return <Table.Cell
                            key={client}
                            style={{
                            backgroundColor: i % 2 !== 0 ? Colors.Even : Colors.Odd,
                            color: Colors.Text,
                            borderRight: `1px solid ${Colors.Border}`
                        }}>
                            {
                                field === "streamTypes"
                                ?
                                JSON.stringify(data.find(x => x.clientName === client) && data.find(x => x.clientName === client)[field][item.type])
                                :
                                    data.find(x => x.clientName === client)
                                    &&
                                    data.find(x => x.clientName === client)[field].find(x => x.sport === item.type) !== undefined
                                        ?
                                        data.find(x => x.clientName === client)
                                        &&
                                        data.find(x => x.clientName === client)[field].find(x => x.sport === item.type).count
                                        :
                                        ""
                            }
                        </Table.Cell>
                    })
                }
            </Table.Row>
        })
    }

    const setDataForFileLoading = () => {
        const loadingFileData = data.filter(value => columns.includes(value.clientName))
        setFileData(loadingFileData);
    }

    const refreshPageData = (startDate: Moment | null, endDate: Moment | null) => {
        if ([Role.ClientAdmin].includes(user!.role)) {
            dispatch(getStatisticsTableDataForGroupRoutine({ groupId: user!.groupId, startDate: startDate!.clone().format("YYYY-MM-DDTHH:mm:ss"),
                endDate: endDate!.clone().add(1, 'd').format("YYYY-MM-DDTHH:mm:ss") }));
        }
        if ([Role.Admin, Role.SuperAdmin, Role.MultiClientReadOnlyAdmin].includes(user!.role)) {
            dispatch(getStatisticsTableDataRoutine({ startDate: startDate!.clone().format("YYYY-MM-DDTHH:mm:ss"),
                endDate: endDate!.clone().add(1, 'd').format("YYYY-MM-DDTHH:mm:ss") }));
        }
    };

    const downloadSummaryStatistics = (startDate: Moment | null, endDate: Moment | null) : Promise<AxiosResponse<any>> => {
        if ([Role.ClientAdmin, Role.ClientAnalyst, Role.ClientSeniorAnalyst].includes(user!.role)) {
            return downloadGroupSummaryCsv(user!.groupId, startDate!.format("YYYY-MM-DDTHH:mm:ss"), endDate!.format("YYYY-MM-DDTHH:mm:ss"));
        }
        else {
            return downloadSummaryCsv(startDate!.format("YYYY-MM-DDTHH:mm:ss"), endDate!.format("YYYY-MM-DDTHH:mm:ss"));
        }
    };

    return <div className='statistics-general-table-container'>
        {
            loading
                ?
            <Loader active content='Loading page data...'/>
                :
                <>
                    <div className="controls">
                        <ClientsDropdown
                            possibleOptions={allPossibleColumns}
                            onChange={setColumnsData}
                            values={columns}
                        />
                        <DateRangePicker
                            displayFormat={() => "DD/MM/YYYY"}
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={({ startDate, endDate }) => {
                                datesChangeHandler(startDate, endDate);
                            }}
                            startDateId={moment().toString()}
                            endDateId={moment().add('7', 'd').toString()}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => changeFocusedInput(focusedInput)}
                            isOutsideRange={day => isInclusivelyAfterDay(day + 1, moment())}
                            minimumNights={0}
                        />
                    </div>
                    {
                        data.length
                            ?
                            <Fragment>
                                <h1>Summary</h1>
                                <div className='button-container'>
                                    <Button onClick={() => refreshPageData(startDate, endDate)}>
                                        Refresh
                                    </Button>
                                    <Button onClick={() => downloadSummaryStatistics(startDate, endDate).then((res) => downloadTableData(res!.data))}>
                                        Download CSV
                                    </Button>
                                </div>
                                <div className="statistics-table-wrapper" style={{ overflowX: columns.length > 7 ? 'scroll' : 'auto'}}>
                                    <Table definition style={{ border: 'none'}}>
                                        <Table.Header>
                                            {
                                                renderColumnsRow()
                                            }
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                renderRow('# of Users', 'usersCount', 1)
                                            }
                                            {
                                                renderRow('# of Sessions', 'sessionsCount', 2)
                                            }
                                            {
                                                renderRow('Total Duration (in hours)', 'durationHours', 3)
                                            }
                                            {
                                                renderEmptyRowWithDropDown('Types', setTypesExpanded, !typesExpanded, 4)
                                            }
                                            {
                                                renderSubitemsRow(typesExpanded, types, 'streamTypes')
                                            }
                                            {
                                                renderEmptyRowWithDropDown('Sports', setSportsExpanded, !sportsExpanded, 5)
                                            }
                                            {
                                                renderSubitemsRow(sportsExpanded, sports, 'sports')
                                            }
                                            {
                                                renderRow('# of Events', 'eventsCount', 6)
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </Fragment>

                            : <div className='empty'>Dataset is empty...</div>
                    }
                </>
        }
    </div>
}

export default NewStatisticsTablePage;
