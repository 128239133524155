import React, {useState} from 'react';
import {Tab, Form, Input} from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import { IRestrictionProps } from '../../../interfaces/creation-form';
import { Country } from '../../../../../../common/types/country';
import Select from 'react-select';
import './styles.scss';

export interface RestrictionsTabProps {
    restrictions: IRestrictionProps;
    setState: (restrictions: IRestrictionProps) => void;
    sportsList: string[];
    countriesList: Country[];
    competitionsList: string[];
    countryLabel: any;
    isCreationModal: boolean;
}

const useForceUpdate = () => {
    const [, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

const RestrictionsTab: React.FC<RestrictionsTabProps> = ({
    restrictions,
    setState,
    sportsList,
    competitionsList,
    isCreationModal
}) => {
    const forceUpdate = useForceUpdate();

    return (
        <Tab.Pane>
            <Formik
                key={'restrictionsTab'}
                initialValues={{...restrictions, isMaxPlayersEnabled: isCreationModal ? true : restrictions.isMaxPlayersEnabled}}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values, setFieldValue }: FormikProps<IRestrictionProps>) => {

                    const setCheckboxValue = (props: any, fieldName: string) => {
                        setFieldValue(fieldName as any, props.checked)
                        values[fieldName] = props.checked;
                        if (fieldName === "devicesAllowedEnabled" && !props.checked) {
                            values.devicesCount = 0;
                        }
                        setState(values);
                    }
                    return <Form size="large">
                        <div className='restriction-tab__container'>
                            <div className="separate-section streams-section">
                                <Form.Checkbox
                                    label="RTMP Link Access"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isRtmpEnabled')}
                                    name="isRtmpEnabled"
                                    checked={values.isRtmpEnabled} />
                                <Form.Checkbox
                                    label="Max Players"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isMaxPlayersEnabled')}
                                    name="isMaxPlayersEnabled"
                                    checked={values.isMaxPlayersEnabled} />
                                <Form.Checkbox
                                    label="Device Restrictions"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'devicesAllowedEnabled')}
                                    name="devicesAllowedEnabled"
                                    checked={values.devicesAllowedEnabled } />
                                <Form.Input
                                    disabled={!values.devicesAllowedEnabled}
                                    label="Devices Count"
                                    placeholder='Enter Devices Number'
                                    name="devicesCount"
                                    id="devicesCount"
                                    onChange={(_, props) => {
                                        setFieldValue("devicesCount", props.value)
                                        values.devicesCount = Number(props.value);
                                        setState(values)
                                    }}
                                    type="number"
                                    value={!values.devicesAllowedEnabled ? 0 : values.devicesCount} />
                            </div>
                            <div className="separate-section download-section">
                                <Form.Checkbox
                                    label="Schedule Download"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isDocumentsDownloadEnabled')}
                                    name="isDocumentsDownloadEnabled"
                                    checked={values.isDocumentsDownloadEnabled} />
                                <Form.Checkbox
                                    label="VOD Download"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isStreamsDownloadEnabled')}
                                    name="isStreamsDownloadEnabled"
                                    checked={values.isStreamsDownloadEnabled} />
                                <Form.Checkbox
                                    label="VOD Download via Schedule API"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isScheduleApiStreamDownloadEnabled')}
                                    name="isScheduleApiStreamDownloadEnabled"
                                    checked={values.isScheduleApiStreamDownloadEnabled} />
                                <Form.Checkbox
                                    label="VOD Clipping"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isVodClippingEnabled')}
                                    name="isVodClippingEnabled"
                                    checked={values.isVodClippingEnabled} />
                            </div>
                            <div className="separate-section domains-section">
                                <Form.Checkbox
                                    label="Domain-only Access"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isDomainOnlyEnabled')}
                                    name="isDomainOnlyEnabled"
                                    id="isDomainOnlyEnabled"
                                    checked={values.isDomainOnlyEnabled} />
                                <Form.Input
                                    label='Domain Names'
                                    placeholder='Enter domain names (separated by coma)'
                                    name="domainsWithAccess"
                                    id="domainsWithAccess"
                                    onChange={(_, data) => {
                                        setFieldValue("domainsWithAccess", data.value)
                                        values.domainsWithAccess = data.value.trim()
                                        setState(values)
                                    }}
                                    type="text"
                                    value={values.domainsWithAccess} />
                            </div>
                            <div className="separate-section rest-section">
                                <Form.Checkbox
                                    label="Sky Boxes"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isSkyBoxesAllowed')}
                                    name="isSkyBoxesAllowed"
                                    id="isSkyBoxesAllowed"
                                    checked={values.isSkyBoxesAllowed} />
                                <Form.Checkbox
                                    label="Internal Streams"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'isInternalStreamsAllowed')}
                                    name="isInternalStreamsAllowed"
                                    id="isInternalStreamsAllowed"
                                    checked={values.isInternalStreamsAllowed} />
                                <Form.Checkbox
                                    label="24 hr Sport News"
                                    type="checkbox"
                                    onChange={(_, props) => setCheckboxValue(props, 'is24HrEnabled')}
                                    name="is24HrEnabled"
                                    checked={values.is24HrEnabled} />
                            </div>
                            <div className="separate-section categories-section">
                                <Form.Field>
                                    <Form.Checkbox
                                        label="Select Required Sport"
                                        type="checkbox"
                                        onChange={(_, props) => setCheckboxValue(props, 'isRequiredSport')}
                                        name="isRequiredSport"
                                        checked={values.isRequiredSport} />
                                    <Select
                                        isMulti
                                        placeholder='Select sports'
                                        onChange={(sports: any) => {
                                            values.disabledSports = (sports && sports.map((s: any) => s.value)) || [];
                                            forceUpdate();
                                            setState(values);
                                        }}
                                        value={values.disabledSports
                                            ?
                                            values.disabledSports.map(s => {
                                                return {
                                                    value: s,
                                                    label: s
                                                }
                                            })
                                            :
                                            []
                                        }
                                        options={sportsList.map(s => {
                                            return {
                                                value: s,
                                                label: s
                                            }
                                        })}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        label="Select Required Competitions"
                                        type="checkbox"
                                        onChange={(_, props) => setCheckboxValue(props, 'isRequiredCompetition')}
                                        name="isRequiredCompetition"
                                        checked={values.isRequiredCompetition} />
                                    <Select
                                        placeholder='Select competitions'
                                        isMulti
                                        onChange={(competitions: any) => {
                                            values.disabledCompetitions = competitions && competitions.map((s: HTMLInputElement) => s.value) || [];
                                            forceUpdate();
                                            setState(values);
                                        }}
                                        value={values.disabledCompetitions
                                            ?
                                            values.disabledCompetitions.map(c => {
                                                return {
                                                    value: c,
                                                    label: c
                                                }
                                            })
                                            :
                                            []
                                        }
                                        options={competitionsList.map(s => {
                                            return {
                                                value: s,
                                                label: s
                                            }
                                        })}
                                    />
                                </Form.Field>
                            </div>
                        </div>
                    </Form>
                }} />
        </Tab.Pane>
    )
}

export default RestrictionsTab;
