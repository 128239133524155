import React from "react";
import { Modal, Tab, Button } from "semantic-ui-react";
import { connect } from 'react-redux';
import { Country } from "../../../../common/types/country";
import { State } from "../../../../common/types/state";
import './styles.scss'
import { actions as toastrActions } from 'react-redux-toastr'
import { bindActionCreators } from 'redux';
import {User} from "../../../../common/types/user";
import MainInfoTabReadonly from "./children/main-info-tab-readonly/main-info-tab-readonly";
import RestrictionsTabReadonly from "./children/restrictions-tab-readonly/restrictions-tab-readonly";
import StreamingTabReadonly from "./children/streaming-tab-readonly/streaming-tab-readonly";
import DataProvidersTabReadonly from "./children/data-providers-tab-readonly/data-providers-tab-readonly";

export interface IGroupModalReadonlyProps {
    isOpened: boolean;
    currentUser: User;
    tabIndex: number;
    setTabIndex: (index: number) => void;
    countries: Country[];
    handleReadOnlyGroupModalClose: () => void;
    loading: boolean;
}

export interface IGroupModalReadonlyState {
    formPage: number,
    modalProps: any;
}

class GroupModalReadonly extends React.Component<IGroupModalReadonlyProps, IGroupModalReadonlyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            formPage: 0,
            modalProps: null,
        };
    }

    tabs = ['Main Info', 'Restrictions', 'Streaming', 'Contract Specs', 'Content Providers'];

    componentWillUnmount() {
        this.props.setTabIndex(0);
    }

    groupMapper = (dc) => {
        const isString = typeof (dc) === 'string';
        return isString ? this.props.countries.find(x => x.alpha2Code === dc.toUpperCase()) : dc;
    }

    moveBack = () => {
        this.props.setTabIndex(this.props.tabIndex - 1);
    }

    moveForward = () => {
        if (this.props.tabIndex + 1 !== this.tabs.length - 1) {
            this.props.setTabIndex(this.props.tabIndex + 1)
        }
    }

    handleClose = () => {
        this.props.handleReadOnlyGroupModalClose();
        this.props.setTabIndex(0);
    }

    renderButtons = (panes) => (
        <>
            {
                this.props.tabIndex !== 0 &&
                <Button type='button' onClick={this.moveBack.bind(this)} color="grey">
                    Back
                </Button>
            }
            {
                this.props.tabIndex !== panes.length - 1 &&
                <Button type='button' onClick={this.moveForward.bind(this)} color="green">
                    Next
                </Button>
            }
        </>
    )

    render() {
        if (this.props.loading) return <div>loading...</div>

        const panes = [
            {
                menuItem: 'Main Info',
                render: () => <MainInfoTabReadonly/>,
            },
            {
                menuItem: 'Restrictions',
                render: () => <RestrictionsTabReadonly/>
            },
            {
                menuItem: 'Streaming',
                render: () => <StreamingTabReadonly/>
            },
            {
                menuItem: 'Content Providers',
                render: () => <DataProvidersTabReadonly/>
            },
        ];

        const { isOpened } = this.props;

        return (
            <Modal
                open={isOpened}
                closeOnDimmerClick={true}
                >
                <Modal.Header>{"View group"}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Tab panes={panes} activeIndex={this.props.tabIndex}/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions style={{ margin: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="group-modal__buttons">
                        {
                            this.renderButtons(panes)
                        }
                        <Button type='button' onClick={this.handleClose} className="ui red basic button cancel-button">Close</Button>
                    </div>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        currentUser: state.user,
        countries: state.countries,
        loading: state.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    toastr: bindActionCreators(toastrActions as any, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(GroupModalReadonly);
