import { Routine } from 'redux-saga-routines';
import {fetchContentProviders, fetchCountries, fetchRoles, fetchSports} from '../../routines';

export const countriesReducer = (state = null, action: Routine<any>) => {
    switch (action.type) {
        case fetchCountries.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export const sportsReducer = (state = null, action: Routine<any>) => {
    switch (action.type) {
        case fetchSports.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export const rolesReducer = (state = null, action: Routine<any>) => {
    switch (action.type) {
        case fetchRoles.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export const contentProvidersReducer = (state = null, action: Routine<any>) => {
    switch (action.type) {
        case fetchContentProviders.SUCCESS:
            return action.payload;
        default:
            return state;
    }
}



