import React, {useEffect, useState} from 'react';
import {Tab, Form, Dropdown} from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import { IDataProvidersProps } from "../../../interfaces/creation-form";

import "./styles.scss";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";
import {fetchContentProviders} from "../../../../../../redux/routines";

interface IDataProvidersTabProps {
    values: IDataProvidersProps;
    setState: (values: IDataProvidersProps) => void;
}

const DataProvidersTab: React.FC<IDataProvidersTabProps> = ({ values, setState }) => {

    const [contentProvidersOptions, setContentProvidersOptions] = useState<{
        value: string;
        text: string;
    }[]>([]);
    const [isProvider, setIsProvider] = useState(true);
    const {contentProviders} = useSelector((state: State) => state);
    const dispatch = useDispatch();

    const providersData = [
        {
            name: "SportRadar",
            enableFieldName: "isSPRAVEnabled",
            paywallFieldName: "isSPRAVPaywallEnabled",
            enable: values["isSPRAVEnabled"],
            paywall: values["isSPRAVPaywallEnabled"]
        },
        {
            name: "Stats Perform",
            enableFieldName: "isStatsPerformContentEnabled",
            paywallFieldName: "isStatsPerformPaywallEnabled",
            enable: values["isStatsPerformContentEnabled"],
            paywall: values["isStatsPerformPaywallEnabled"]
        },
        {
            name: "InPlay",
            enableFieldName: "isInPlayContentEnabled",
            paywallFieldName: "isInPlayPaywallEnabled",
            enable: values["isInPlayContentEnabled"],
            paywall: values["isInPlayPaywallEnabled"]
        },
        {
            name: "XVI",
            enableFieldName: "isXVIContentEnabled",
            paywallFieldName: "isXVIPaywallEnabled",
            enable: values["isXVIContentEnabled"],
            paywall: values["isXVIPaywallEnabled"]
        },
        {
            name: "Modus",
            enableFieldName: "isModusContentEnabled",
            paywallFieldName: "isModusPaywallEnabled",
            enable: values["isModusContentEnabled"],
            paywall: values["isModusPaywallEnabled"]
        },
        {
            name: "Internal Schedule",
            enableFieldName: "isInternalScheduleContentEnabled",
            paywallFieldName: "isInternalSchedulePaywallEnabled",
            enable: values["isInternalScheduleContentEnabled"],
            paywall: values["isInternalSchedulePaywallEnabled"]
        },
        {
            name: "Spring Media",
            enableFieldName: "isSpringMediaContentEnabled",
            paywallFieldName: "isSpringMediaPaywallEnabled",
            enable: values["isSpringMediaContentEnabled"],
            paywall: values["isSpringMediaPaywallEnabled"]
        },
        {
            name: "Infront",
            enableFieldName: "isInfrontContentEnabled",
            paywallFieldName: "isInfrontPaywallEnabled",
            enable: values["isInfrontContentEnabled"],
            paywall: values["isInfrontPaywallEnabled"]
        },
        {
            name: "CSM",
            enableFieldName: "isCSMContentEnabled",
            paywallFieldName: "isCSMPaywallEnabled",
            enable: values["isCSMContentEnabled"],
            paywall: values["isCSMPaywallEnabled"]
        },
        {
            name: "TDI",
            enableFieldName: "isTDIContentEnabled",
            paywallFieldName: "isTDIPaywallEnabled",
            enable: values["isTDIContentEnabled"],
            paywall: values["isTDIPaywallEnabled"]
        },
        {
            name: "Trinta",
            enableFieldName: "isTrintaContentEnabled",
            paywallFieldName: "isTrintaPaywallEnabled",
            enable: values["isTrintaContentEnabled"],
            paywall: values["isTrintaPaywallEnabled"]
        },
        {
            name: "BOXXER",
            enableFieldName: "isBOXXERContentEnabled",
            paywallFieldName: "isBOXXERPaywallEnabled",
            enable: values["isBOXXERContentEnabled"],
            paywall: values["isBOXXERPaywallEnabled"]
        },
        {
            name: "SailGP",
            enableFieldName: "isSailGPContentEnabled",
            paywallFieldName: "isSailGPPaywallEnabled",
            enable: values["isSailGPContentEnabled"],
            paywall: values["isSailGPPaywallEnabled"]
        }
    ]

    useEffect(() => {
       dispatch(fetchContentProviders());
    }, []);

    useEffect(() => {
        if (values.contentProvider) {
            setIsProvider(true);
        } else {
            setIsProvider(false);
        }
    }, [values.contentProvider]);

    useEffect(() => {
        const options = contentProviders?.map(providerItem => ({
            key: providerItem,
            value: providerItem,
            text: providerItem
        }))
        setContentProvidersOptions(options);
    }, [contentProviders]);

    return <Tab.Pane>
        <Formik key={'dataProvidersTab'}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
            render={({ values, setFieldValue }: FormikProps<IDataProvidersProps>) => {

                const setCheckboxValue = (props: any, fieldName: string, provider) => {
                    setFieldValue(fieldName as any, props.checked)
                    values[fieldName] = props.checked;
                    if (props.checked === true) {
                        if (fieldName === provider.enableFieldName ) {
                            setFieldValue(provider.paywallFieldName as any, false)
                            values[provider.paywallFieldName] = false;
                            setState(values);
                        } else {
                            setFieldValue(provider.enableFieldName as any, false)
                            values[provider.enableFieldName] = false;
                            setState(values);
                        }
                    }
                    setState(values);
                }

                const setContentProviderValue = (data: any) => {
                    values.contentProvider = data;
                    values.accessToUsageFrom = [data];
                    setState(values);
                }

                const setAccessesValue = (data: any) => {
                    if (!data.includes(values.contentProvider)) {
                        values.accessToUsageFrom = [values.contentProvider, ...data];
                        setState(values);
                    } else {
                        values.accessToUsageFrom = data;
                        setState(values);
                    }
                }

                const skipDropdownsValues = () => {
                    values.contentProvider = "";
                    values.accessToUsageFrom = [];
                    setState(values);
                }

                const handleSetIsProvider = (checked: boolean | undefined) => {
                    if (checked) {
                        setIsProvider(true);
                    } else {
                        setIsProvider(false);
                        skipDropdownsValues();
                    }
                }

                return <Form size="large" className="content-providers-form">
                    <div className="providers-list">
                        <div className="providers-header">
                            <span className="providers-header__enabled">Enabled</span>
                            <span className="providers-header__paywall">Paywall</span>
                        </div>
                        {
                            providersData.map(item => {
                                return (
                                    <div className="provider-section">
                                        <label>{item.name}</label>
                                        <Form.Checkbox
                                            type="checkbox"
                                            onChange={(_, props) => setCheckboxValue(props, item.enableFieldName, item)}
                                            name={item.enableFieldName}
                                            checked={values[item.enableFieldName]} />
                                        <Form.Checkbox
                                            type="checkbox"
                                            onChange={(_, props) => setCheckboxValue(props, item.paywallFieldName, item)}
                                            name={item.paywallFieldName}
                                            checked={values[item.paywallFieldName]} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="statistics-access">
                        <Form.Checkbox
                            className="is-provider-checkbox"
                            label="Is Content Provider?"
                            type="checkbox"
                            onChange={(_, props) => handleSetIsProvider(props.checked)}
                            name="isProvider"
                            checked={isProvider} />
                        <Form.Field inline>
                            <label className={!isProvider ? "label-disabled" : ""}>Content Provider</label>
                            <Dropdown
                                onChange={(_, data) => setContentProviderValue(data.value)}
                                placeholder={isProvider ? "Select Content Provider" : ""}
                                fluid
                                search
                                selection
                                multiple={false}
                                disabled={!isProvider}
                                value={values.contentProvider ?? ""}
                                options={contentProvidersOptions}
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <label className={!isProvider ? "label-disabled" : ""}>Access To Usage From</label>
                            <Dropdown
                                onChange={(_, data) => setAccessesValue(data.value)}
                                placeholder={isProvider ? "Select Content Supplier" : ""}
                                fluid
                                search
                                selection
                                multiple={true}
                                disabled={!isProvider}
                                value={values.accessToUsageFrom}
                                options={contentProvidersOptions}
                            />
                        </Form.Field>
                    </div>
                </Form>
            }}
        />
    </Tab.Pane>
}

export default DataProvidersTab
