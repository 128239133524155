import React from 'react';
import {Tab, Form, Dropdown} from 'semantic-ui-react';
import { Formik, FormikProps } from 'formik';
import Select from 'react-select';
import './styles.scss';
import {useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";
import {Restriction} from "../../../../../../common/types/restriction";

const RestrictionsTabReadonly: React.FC = ({}) => {
    const group = useSelector((state: State) => state.groups.singleGroup);
    const {sports, competitions} = useSelector((state: State) => state);

    return (
        group &&
        <Tab.Pane>
            <Formik
                key={'restrictionsTab'}
                initialValues={group.restriction}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values }: FormikProps<Restriction>) => {

                    return <Form size="large">
                        <div className='restriction-tab__container'>
                            <div className="separate-section streams-section">
                                <Form.Checkbox
                                    label="RTMP Link Access"
                                    type="checkbox"
                                    name="isRtmpEnabled"
                                    disabled={true}
                                    checked={values.isRtmpEnabled} />
                                <Form.Checkbox
                                    label="Max Players"
                                    type="checkbox"
                                    name="isMaxPlayersEnabled"
                                    disabled={true}
                                    checked={values.isMaxPlayersEnabled} />
                                <Form.Checkbox
                                    label="Device Restrictions"
                                    type="checkbox"
                                    name="devicesAllowedEnabled"
                                    disabled={true}
                                    checked={values.devicesAllowedEnabled} />
                                <Form.Input
                                    label="Devices Count"
                                    placeholder='Enter Devices Number'
                                    name="devicesCount"
                                    disabled={true}
                                    id="devicesCount"
                                    type="number"
                                    value={!values.devicesAllowedEnabled ? 0 : values.devicesCount} />
                            </div>
                            <div className="separate-section download-section">
                                <Form.Checkbox
                                    label="Schedule Download"
                                    type="checkbox"
                                    name="isDocumentsDownloadEnabled"
                                    disabled={true}
                                    checked={values.isDocumentsDownloadEnabled} />
                                <Form.Checkbox
                                    label="VOD Download"
                                    type="checkbox"
                                    name="isStreamsDownloadEnabled"
                                    disabled={true}
                                    checked={values.isStreamsDownloadEnabled} />
                                <Form.Checkbox
                                    label="VOD Download via Schedule API"
                                    type="checkbox"
                                    name="isScheduleApiStreamDownloadEnabled"
                                    disabled={true}
                                    checked={values.isScheduleApiStreamDownloadEnabled} />
                                <Form.Checkbox
                                    label="VOD Clipping"
                                    type="checkbox"
                                    name="isVodClippingEnabled"
                                    disabled={true}
                                    checked={values.isVodClippingEnabled} />
                            </div>
                            <div className="separate-section domains-section">
                                <Form.Checkbox
                                    label="Domain-only Access"
                                    type="checkbox"
                                    name="isDomainOnlyEnabled"
                                    disabled={true}
                                    id="isDomainOnlyEnabled"
                                    checked={values.isDomainOnlyEnabled} />
                                <Form.Input
                                    label='Domain Names'
                                    placeholder='Enter domain names (separated by coma)'
                                    name="domainsWithAccess"
                                    disabled={true}
                                    id="domainsWithAccess"
                                    type="text"
                                    value={values.domainsWithAccess} />
                            </div>
                            <div className="separate-section rest-section">
                                <Form.Checkbox
                                    label="Sky Boxes"
                                    type="checkbox"
                                    name="isSkyBoxesAllowed"
                                    disabled={true}
                                    id="isSkyBoxesAllowed"
                                    checked={values.isSkyBoxesAllowed} />
                                <Form.Checkbox
                                    label="Internal Streams"
                                    type="checkbox"
                                    name="isInternalStreamsAllowed"
                                    disabled={true}
                                    id="isInternalStreamsAllowed"
                                    checked={values.isInternalStreamsAllowed} />
                                <Form.Checkbox
                                    label="24 hr Sport News"
                                    type="checkbox"
                                    name="is24HrEnabled"
                                    disabled={true}
                                    checked={values.is24HrEnabled} />
                            </div>
                            <div className="separate-section categories-section">
                                <Form.Field>
                                    <Form.Checkbox
                                        label="Select Required Sport"
                                        type="checkbox"
                                        name="isRequiredSport"
                                        disabled={true}
                                        checked={values.isRequiredSport} />
                                    <Dropdown
                                        placeholder={"Select Sport"}
                                        fluid
                                        search
                                        selection
                                        multiple={true}
                                        disabled={true}
                                        value={values.disabledSports}
                                        options={sports && sports.map(sport => {
                                            return {
                                                key: sport,
                                                value: sport,
                                                text: sport,
                                            }
                                        })}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        label="Select Required Competitions"
                                        type="checkbox"
                                        name="isRequiredCompetition"
                                        disabled={true}
                                        checked={values.isRequiredCompetition} />
                                    <Dropdown
                                        placeholder={"Select Competitions"}
                                        fluid
                                        search
                                        selection
                                        multiple={true}
                                        disabled={true}
                                        value={values.disabledCompetitions}
                                        options={competitions && competitions.map(competition => {
                                            return {
                                                key: competition,
                                                value: competition,
                                                text: competition,
                                            }
                                        })}
                                    />
                                </Form.Field>
                            </div>
                        </div>
                    </Form>
                }} />
        </Tab.Pane>
    )
}

export default RestrictionsTabReadonly;
