import React, {useEffect, useState} from 'react';
import {Tab, Form, Dropdown} from 'semantic-ui-react';
import { Formik } from 'formik';
import "./styles.scss";
import {useSelector} from "react-redux";
import {State} from "../../../../../../common/types/state";

export interface DataItem {
    name: string;
    enableFieldName: string;
    paywallFieldName: string;
    enable: boolean;
    paywall: boolean;
}

const DataProvidersTabReadonly: React.FC = () => {
    const group = useSelector((state: State) => state.groups.singleGroup);
    const [providersData, setProvidersData] = useState<DataItem[] | null>(null);

    useEffect(() => {
       if (group) {
           setProvidersData([
               {
                   name: "Stats Perform",
                   enableFieldName: "isStatsPerformContentEnabled",
                   paywallFieldName: "isStatsPerformPaywallEnabled",
                   enable: group["isStatsPerformContentEnabled"],
                   paywall: group["isStatsPerformPaywallEnabled"]
               },
               {
                   name: "InPlay",
                   enableFieldName: "isInPlayContentEnabled",
                   paywallFieldName: "isInPlayPaywallEnabled",
                   enable: group["isInPlayContentEnabled"],
                   paywall: group["isInPlayPaywallEnabled"]
               },
               {
                   name: "Modus",
                   enableFieldName: "isModusContentEnabled",
                   paywallFieldName: "isModusPaywallEnabled",
                   enable: group["isModusContentEnabled"],
                   paywall: group["isModusPaywallEnabled"]
               },
               {
                   name: "Infront",
                   enableFieldName: "isInfrontContentEnabled",
                   paywallFieldName: "isInfrontPaywallEnabled",
                   enable: group["isInfrontContentEnabled"],
                   paywall: group["isInfrontPaywallEnabled"]
               }
           ])
       }
    }, [group]);

    const getReadonlyContentProviderOptions = (providerValue: string | null) => {
        return providerValue
            ?
            [{key: providerValue, value: providerValue, text: providerValue}]
            :
            [{key: "", value: "", text: ""}]
    }

    const getReadonlyAccessToUsageFromOptions = (providersValues: string[]) => {
        return providersValues
            ?
            providersValues.map(item => ({key: item, value: item, text: item}))
            :
            [{key: "", value: "", text: ""}]
    }

    return (
        group &&
        <Tab.Pane>
            <Formik key={'dataProvidersTab'}
                initialValues={group.restriction}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                render={({ values }) => {

                    return <Form size="large" className="content-providers-form">
                        <div className="providers-list">
                            <div className="providers-header">
                                <span className="providers-header__enabled">Enabled</span>
                                <span className="providers-header__paywall">Paywall</span>
                            </div>
                            {
                                providersData?.map(item => {
                                    return (
                                        <div className="provider-section">
                                            <label>{item.name}</label>
                                            <Form.Checkbox
                                                type="checkbox"
                                                disabled={true}
                                                name={item.enableFieldName}
                                                checked={values[item.enableFieldName]} />
                                            <Form.Checkbox
                                                type="checkbox"
                                                disabled={true}
                                                name={item.paywallFieldName}
                                                checked={values[item.paywallFieldName]} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="statistics-access">
                            <Form.Checkbox
                                disabled={true}
                                className="is-provider-checkbox"
                                label="Is Content Provider?"
                                type="checkbox"
                                name="isProvider"
                                checked={!!values.contentProvider} />
                            <Form.Field inline>
                                <label className="label-disabled">Content Provider</label>
                                <Dropdown
                                    fluid
                                    search
                                    selection
                                    multiple={false}
                                    disabled={true}
                                    value={values.contentProvider ?? ""}
                                    options={getReadonlyContentProviderOptions(values.contentProvider)}
                                />
                            </Form.Field>
                            <Form.Field inline>
                                <label className="label-disabled">Access To Usage From</label>
                                <Dropdown
                                    fluid
                                    search
                                    selection
                                    multiple={true}
                                    disabled={true}
                                    value={values.accessToUsageFrom}
                                    options={getReadonlyAccessToUsageFromOptions(values.accessToUsageFrom)}
                                />
                            </Form.Field>
                        </div>
                    </Form>
                }}
            />
        </Tab.Pane>
    )
}

export default DataProvidersTabReadonly
